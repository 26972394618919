<template>
  <div class="layoutContainer">
    <div class="tableDetail">
      <el-button type="primary" @click="addRootMenu()" icon="el-icon-plus">新增一级菜单</el-button>
      <el-table style="width:100%" border :data="resultList"
       row-key="id"
       :tree-props="{children:'children',hasChildren:'hasChildren'}"
      >
        <el-table-column label="菜单结构" prop="name" align="center"  width="200"></el-table-column>
        <el-table-column label="编码" prop="code" align="center"  width="200"></el-table-column>
        <el-table-column label="父级编码" prop="pcode" align="center"  width="200"></el-table-column>
        <el-table-column label="地址" prop="url" align="center" width="250"></el-table-column>
        <el-table-column label="类型" prop="type" align="center" width="100">
          <template slot-scope="{row}">
            <span v-if="row.type == '1'">系统</span>
            <span v-if="row.type == '2'">主菜单</span>
            <span v-if="row.type == '3'">二级菜单</span>
          </template>
        </el-table-column>
        <el-table-column label="排序" prop="sort" align="center" width="100"></el-table-column>
        <el-table-column label="备注" prop="memo" align="center" width="200"></el-table-column>
        <el-table-column label="状态" prop="userFlag" align="center" width="100">
          <template slot-scope="{row}">
            <span v-if="row.useFlag === 1" style="color:#009933"> 启用</span>
            <span v-if="row.useFlag === 0" style="color:#cc0000"> 禁用</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300" fixed="right">
          <template slot-scope="{row}">
            <el-button type="primary" size="mini" @click="handelDetail(row)">查看</el-button>
            <el-button type="primary" size="mini" @click="handelEdit(row)">编辑</el-button>
            <el-button type="primary" size="mini" style="background:#ff5722" @click="handelDelete(row)">删除</el-button>
            <el-button type="primary" size="mini" @click="addSubMenu(row)"  v-if="row.type == '2'" >添加子项</el-button>
            <el-button type="primary" size="mini" @click="assignButton(row)" v-if="row.type == '3'" >分配按钮</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :before-close="handleCloseCreate">
        <!-- 新增 编辑 添加子项-->
        <el-form v-if="dialogStatus === 'add' || dialogStatus === 'edit' || dialogStatus === 'child'" :model="temp" ref="temp" :rules="rules" label-position="center">
          <el-form-item label="菜单名" prop="name" :label-width="formLabelWidth">
            <el-input v-model="temp.name"  @input="e => temp.name= validForbid(e)" show-word-limit maxlength="20"  style="padding:0 48px 0 0" placeholder="请输入菜单名"></el-input>
          </el-form-item>
          <el-form-item label="完整编码" prop="code" :label-width="formLabelWidth">
            <el-input v-model="temp.code" @input="e => temp.code= validForbid(e)" :disabled="true" show-word-limit maxlength="20"  style="padding:0 48px 0 0"  :placeholder="menuCode"></el-input>
          </el-form-item>
          <el-form-item label="编码后缀" prop="suffix" :label-width="formLabelWidth">
            <el-input v-model="temp.suffix" @input="e => temp.suffix= validForbid(e)" show-word-limit maxlength="10"  style="padding:0 48px 0 0"  placeholder="请输入编码后缀"></el-input>
          </el-form-item>
          <el-form-item label="URL地址" prop="url" :label-width="formLabelWidth">
            <el-input v-model="temp.url" show-word-limit maxlength="100"  placeholder="请输入URL地址"  style="padding:0 48px 0 0" ></el-input>
          </el-form-item>
          <el-form-item label="图标：" prop="icon" :label-width="formLabelWidth">
            <!-- <el-upload
              :action="uploadUrl"
              :headers="importHeader"
              :limit="1"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog> -->
   
            <el-upload
              class="upload-demo"
              :action="uploadUrl"
              :headers="importHeader"
              :on-success="handleOnSuccess"
              :on-remove="handleOnRemove"
              :before-remove="beforeOnRemove"
              :before-upload="beforeAvatarUpload"
              :on-exceed="handleOnExceed"
               multiple
              :limit="1"
              :file-list="imgList"
              accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
              list-type="picture"
            >
            
              <el-button size="min" icon="el-icon-upload" type="primary">选择图片</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="排序" prop="sort" :label-width="formLabelWidth">
            <el-input type="number"  :min="1" :max="9999"  @input="e => temp.sort=parserNumber(e,1,9999)" v-model="temp.sort"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="memo" :label-width="formLabelWidth">
            <el-input v-model="temp.memo" show-word-limit maxlength="50"   style="padding:0 48px 0 0"  placeholder="请输入备注"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="useFlag" :label-width="formLabelWidth">
            <el-switch v-model="whether"></el-switch>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" @click="dialogStatus === 'edit' ? updateSubmit('temp') : addSubmit('temp') ">
              立即提交
            </el-button>
            <el-button @click="dialogFormVisible = false">
              取消
            </el-button>
          </el-form-item>
        </el-form>
        <!-- 查看 -->
        <el-form v-if="dialogStatus === 'detail'" :rules="rules" :model="temp" ref="temp" label-position="center">
          <el-form-item label="编码" prop="code" :label-width="formLabelWidth">
            <el-input v-model="temp.code" readonly></el-input>
          </el-form-item>
          <el-form-item label="父级编码" prop="pcode" :label-width="formLabelWidth">
            <el-input v-model="temp.pcode" readonly></el-input>
          </el-form-item>
          <el-form-item label="菜单名" prop="name" :label-width="formLabelWidth">
            <el-input v-model="temp.name" readonly></el-input>
          </el-form-item>
          <el-form-item label="菜单地址" prop="url" :label-width="formLabelWidth">
            <el-input v-model="temp.url" readonly></el-input>
          </el-form-item>
          <el-form-item label="类型" prop="type" :label-width="formLabelWidth">
            <el-input v-if="temp.type === 1"  readonly  value="系统"></el-input>
            <el-input v-else-if="temp.type === 2"  readonly value="一级菜单"></el-input>
            <el-input v-else value="二级菜单" readonly ></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="memo" :label-width="formLabelWidth">
            <el-input v-model="temp.memo" readonly></el-input>
          </el-form-item>
          <!-- <el-form-item label="创建用户" prop="createUser" :label-width="formLabelWidth">
            <el-input v-model="temp.createUser" readonly></el-input>
          </el-form-item>
          <el-form-item label="创建时间" prop="createTime" :label-width="formLabelWidth">
            <el-date-picker type="date" v-model="temp.createTime" readonly></el-date-picker>
          </el-form-item>
          <el-form-item label="修改用户" prop="updateUser" :label-width="formLabelWidth">
            <el-input v-model="temp.updateUser" readonly></el-input>
          </el-form-item>
          <el-form-item label="修改时间" prop="updateTime" :label-width="formLabelWidth">
            <el-date-picker v-model="temp.updateTime" type="date" placeholder="选择日期" readonly></el-date-picker>
          </el-form-item> -->
          <el-form-item label="排序" prop="sort" :label-width="formLabelWidth">
            <el-input type="number"  v-model="temp.sort" readonly></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="useFlag" :label-width="formLabelWidth">
            <el-switch v-model="whether" disabled ></el-switch>
          </el-form-item>
        </el-form>
        <!-- 分配按钮 -->
        <el-form v-if="dialogStatus === 'button'" prop="permissions" ref="alloter" :model="alloter">
          <el-checkbox-group v-model="alloter.permissionsCode">
            <el-checkbox border v-for="(item, index) in permissionList" :key="index" :label="item.code">{{item.name}}</el-checkbox>
          </el-checkbox-group>
          <el-form-item>
            <el-button type="primary" @click="submitForm('alloter')">
              确定
            </el-button>
            <el-button @click="dialogFormVisible = false">
              取消
            </el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { sysServerUrl, obj } from '@/assets/js/common.js'
import { menuData, assignBut, menuAdd, menuEdit, menuCheck, menuDel, menuRoles, menuAssign } from '@/api/api.js'
import axios from 'axios'
export default {
  name: 'layoutContainer',
  data () {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      temp: {
        id: 0,
        name: '',
        code: '',
        pcode: '',
        suffix: '',
        url: '',
        icon: '',
        sort: 1,
        type: 2,
        memo: '',
        createUser: '',
        createTime: '',
        updateUser: '',
        updateTiem: '',
        useFlag: 1
      },
      menuCode: '',
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        edit: '编辑菜单',
        detail: '菜单明细',
        add: '新增菜单',
        child: '新增子项菜单',
        button: '分配按钮'
      },
      alloter: {
        menuCode: '',
        permissionsCode: []
      },
      whether: false,
      formLabelWidth: '100px',
      resultList: [],
      permissionList: [],
      uploadUrl: '',
      imgList: [],
      pagesize: 10,
      currentPage: 1,
      rules: {
        name: [{ required: true, message: '菜单名不能为空', trigger: 'blur' }],
        suffix: [{ required: true, message: '编码后缀不能为空', trigger: 'blur' }]
      }
    }
  },
  computed: {
    importHeader: function () {
      return { Authorization: sessionStorage.token }
    }
  },
  methods: {
    // 数据
    dataList () {
      menuData().then(res => {
        if(res.data.code === 100){
          this.resultList = res.data.data
         
        }
      })    
    },
    // 按钮
    getPermissionList () {
      assignBut().then(res => {
        if (res.data.code === 100) {
          this.permissionList = res.data.data
        }
      })
    },
    resetTemp () {
      this.temp = {
        id: 0,
        name: '',
        code: '',
        suffix: '',
        pcode: '',
        url: '',
        icon: '',
        sort: 1,
        memo: '',
        type: 2,
        useFlag: 1
      }
      this.imgList=[]
      this.whether = false
    },
    whetherStates () {
      if (this.whether === false) {
        this.temp.useFlag = 0
      } else {
        this.temp.useFlag = 1
      }
    },
    handleCloseCreate () {
      this.dialogFormVisible = false
      if(this.dialogStatus == 'button'){
        this.$refs.alloter.resetFields()
      }else{
        this.$refs.temp.resetFields()
      }
    },
    // 增加一级菜单
    addRootMenu () {
      this.resetTemp()
      this.temp.code = 'menuAsimss'
      this.temp.pcode="menuAsimss"
      this.imgList=[]
      this.temp.suffix=""
      this.dialogStatus = 'add'
      this.dialogFormVisible = true
      if (this.temp.useFlag === 0) {
        this.whether = false
      } else  {
        this.whether = true
      } 
    },
    // 添加子项
    addSubMenu (row) {
      this.resetTemp()
      this.temp.code = row.code
      this.temp.pcode=row.code
      this.imgList=[]
      this.temp.useFlag=row.useFlag
      this.temp.type=3
      this.temp.suffix=""
      this.dialogStatus = 'child'

      this.dialogFormVisible = true
       if (this.temp.useFlag === 0) {
        this.whether = false
      } else  {
        this.whether = true
      } 
    },
    // 添加
    addSubmit (temp) {
      this.whetherStates()
      this.$refs[temp].validate((valid) => {
        if (valid) {
          var curCode = this.temp.pcode + this.temp.suffix
          var params = new URLSearchParams()
          params.append('name', this.temp.name)
          params.append('code', curCode)
          params.append('url', this.temp.url)
          params.append('icon', this.temp.icon)
          params.append('sort', this.temp.sort)
          params.append('memo', this.temp.memo)
          params.append('type', this.temp.type)
          params.append('useFlag', this.temp.useFlag)
          params.append('pcode', this.temp.pcode)
          menuAdd(params).then(res => {
            if(res.data.code === 100){
              this.$message({
                message: '保存成功',
                type: 'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            }else{
              this.$message.error(res.data.msg)
            }
          }).catch(err => {
            if (err !== null && err !== '' && err.responseText !== null) {
              this.$message.error('提交失败,请重试')
            }
          })
        } else {
          this.$message.error('请完善菜单信息')
        }
      })
    },
    // 编辑
    handelEdit (row) {
      row.suffix = row.code.substring(row.pcode.length)
      this.temp = Object.assign({}, row)
      this.imgList=[]
      if(this.temp.icon!=null&&this.temp.icon.length>0){
        var img = { url:  sysServerUrl + 'sys/upload/display?filePath=' +  this.temp.icon }
        this.imgList.push(img)
      }
      this.dialogStatus = 'edit'
      this.dialogFormVisible = true
      if (this.temp.useFlag === 0) {
        this.whether = false
      } else  {
        this.whether = true
      } 
    },
    // 编辑
    updateSubmit (temp) {
      this.whetherStates()
      this.$refs[temp].validate((valid) => {
        if (valid) {
          var curCode = this.temp.pcode + this.temp.suffix
          var params = new URLSearchParams()
          params.append('id', this.temp.id)
          params.append('name', this.temp.name)
          params.append('code', curCode)
          params.append('url', this.temp.url)
          params.append('icon', this.temp.icon)
          params.append('sort', this.temp.sort)
          params.append('memo', this.temp.memo)
          params.append('type', this.temp.type)
          params.append('useFlag', this.temp.useFlag)
          params.append('pcode', this.temp.pcode)
          menuEdit(params).then(res=>{
            if(res.data.code === 100){
              this.$message({
                message: '保存成功',
                type: 'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            }else{
              this.$message.error(res.data.msg)
            }
          }).catch(err => {
            if (err !== null && err !== '' && err.responseText !== null) {
              this.$message.error('提交失败,请重试')
            }
          })
        } else {
          this.$message.error('请完善菜单信息')
        }
      })
    },
    
    handleOnSuccess (res, obj) {
      this.temp.icon = res.data.fileUrl
      this.imgList=[]
      if(this.temp.icon!=null&&this.temp.icon.length>0){
        // setTimeout(function(){
        var img = { url:  sysServerUrl + 'sys/upload/display?filePath=' +  this.temp.icon }
        this.imgList.push(img)
        // },1000)
      }
    },
    beforeOnRemove(file, fileList) {
      return this.$confirm(`确定移除当前图片？`);
    },
    handleOnRemove(file,fileList){
      if(fileList.length == '0'){
        this.imgList=[]
        this.temp.icon = ""
      }
    },
    beforeAvatarUpload (file) {
      const extension = file.name.split('.')[1] === 'png'
      const extension2 = file.name.split('.')[1] === 'jpg'
      const extension3 = file.name.split('.')[1] === 'jpeg'
      const extension4 = file.name.split('.')[1] === 'gif'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!extension && !extension2 && !extension3 && !extension4) {
        this.$message.warning('上传图片只能是 png、jpg、jpeg、gif格式!')
      }
      if (!isLt2M) {
        this.$message.warning('上传图片大小不能超过 2MB!')
      }
    },
    handleOnExceed (files, fileList) {
      this.$message.warning(`当前限制选择1张图片，本次选择了 ${files.length} 张图片，共选择了 ${files.length + fileList.length} 张图片`)
    },
    // 查看
    handelDetail (row) {
      var params = "?id=" + row.id
      menuCheck(params).then(res => {
        this.temp = Object.assign({}, res.data.data.menu)
        this.temp.createUser = res.data.data.createUser
        this.temp.updateUser = res.data.data.updateUser
        this.temp.createTime = res.data.data.createTime
        this.temp.updateTime = res.data.data.updateTime
        if (this.temp.useFlag === 1) {
          this.whether = true
        } else  {
          this.whether = false
        }
      })
      this.dialogStatus = 'detail'
      this.dialogFormVisible = true
    },
    // 删除
    handelDelete (row) {
      this.$confirm('确定删除 ' + row.name + '?', '删除菜单', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var params = '?id=' + row.id
        menuDel(params).then(res => {
          if(res.data.code === 100){
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.dataList()
          }else{
            this.$message.error(res.data.msg)
          }
        })
      }).catch((error) => {
        this.$message.error('删除失败')
      })
    },
    // 分配按钮
    assignButton (row) {
      this.alloter.menuCode = row.code
      this.alloter.permissionsCode = []
      this.dialogStatus = 'button'
      this.dialogFormVisible = true
      var params = '?menuCode=' + row.code
      menuRoles(params).then(res => {
        var butLength = res.data.data.length
        if (butLength > 0) {
          for (var i = 0; i < butLength; i++) {
            this.alloter.permissionsCode.push(res.data.data[i].permissionCode)
          }
        }
      })
    },
    submitForm (alloter) {
      var list = []
      this.alloter.permissionsCode.forEach(function (item) {
        list.push(item)
      })
      var params = new URLSearchParams()
      params.append('menuCode', this.alloter.menuCode)
      for (var i = 0; i < list.length; i++) {
        params.append('permissions', list[i])
      }
      menuAssign(params).then(res => {
        if(res.data.code === 100){
          this.$message({
            message: '分配成功',
            type: 'success'
          })
          this.dataList()
        }
      })
      this.dialogFormVisible = false
    }
  },
  mounted () {
    this.uploadUrl=sysServerUrl + 'sys/upload/attach?flag=menuIcon'
    this.dataList()
    this.getPermissionList()
  }
}
</script>
